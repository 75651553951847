import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';

const LocationMarker = ({ selected, location, number, onClick }) => {
  const getMapSize = (w, h) =>
    typeof window !== 'undefined' && new window.google.maps.Size(w, h);

  const getMapPoint = (x, y) =>
    typeof window !== 'undefined' && new window.google.maps.Point(x, y);

  const props = {
    position: {
      lat: location?.latitude,
      lng: location?.longitude,
    },
    title: number,
    label: number
      ? {
          text: number,
          color: '#ffffff',
          fontFamily: 'source-sans-pro',
          fontSize: '12px',
          fontWeight: 'bold',
        }
      : null,
    opacity: 0.87,
    onClick,
  };

  const icon = () => {
    return (
      <Marker
        icon={{
          url: '/public-assets/images/map-pin.svg',
          size: getMapSize(24, 32),
          scaledSize: getMapSize(24, 32),
          labelOrigin: getMapPoint(12, 12),
        }}
        {...props}
      />
    );
  };

  const selectedIcon = () => {
    return (
      <Marker
        icon={{
          url: '/public-assets/images/map-pin-selected.svg',
          size: getMapSize(36, 50),
          scaledSize: getMapSize(36, 50),
          labelOrigin: getMapPoint(18, 18),
        }}
        {...props}
        label={{
          ...props.label,
          fontSize: '16px',
        }}
        opacity={1.0}
      />
    );
  };

  return selected ? selectedIcon() : icon();
};

LocationMarker.propTypes = {
  selected: PropTypes.bool,
  location: PropTypes.object,
  number: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(LocationMarker);
