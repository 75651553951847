import styled from 'styled-components';
import {
  mobile_width_percentage,
  tablet_breakpoint,
  desktop_breakpoint,
  desktop_container_maxwidth_compact,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';
import { setAbove } from 'pubweb-smokey/dist/components/zIndex';
import chZindex from '@components/zIndex';

const localZindex = {
  resultsInfo: setAbove(chZindex.filterBars),
  pagingBar: setAbove(),
  searchFilters: setAbove(),
  selectedMapPin: setAbove(),
};

export const LocationsStyles = styled.div`
  .bottom-controls {
    margin-top: 10px;
  }
  /* constrained-width keeps things boxed in while still 
    letting a background image or color go all the way across. */
  .constrained-width {
    margin: 0 auto;
    width: ${mobile_width_percentage}%;
  }

  .map-pin-navigator {
    background: url('/public-assets/images/map-pin.svg') no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #fff;
    width: 25px;
    height: 31px;
    float: left;
    margin-right: 10px;

    .map-text {
      font-size: 12px;
    }

    &.selected {
      background: url('/public-assets/images/map-pin-selected.svg') no-repeat;
      background-size: cover;
    }
  }

  //pins on the map
  .map-pin {
    background: url('/public-assets/images/map-pin-sibling.svg') no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #fff;
    width: 25px;
    height: 31px;

    .map-text {
      font-size: 12px;
    }

    //the currently selected pin
    &.selected {
      background: url('/public-assets/images/map-pin-selected.svg') no-repeat;
      background-size: cover;
      width: 35px;
      height: 44px;
      margin-left: -5px;
      margin-top: -13px;
      position: absolute;
      z-index: ${localZindex.selectedMapPin};

      .map-text {
        font-size: 16px;
        padding-top: 6px;
      }
    }
  }

  .map-text {
    display: block;
    text-align: center;
    width: 100%;
    padding-top: 3px;
  }

  .store-container {
    background-color: #fff;

    .map-pin {
      float: left;
      margin-right: 10px;
    }

    &.selected {
      background-color: ${Colors.accent.veryLightBlue.standard};
    }
  }
  .location-select-box {
    margin: 25px 0 0px 25px;

    a {
      text-decoration: none;
      cursor: pointer;
      svg {
        vertical-align: middle;
        path {
          fill: ${Colors.primary.claytonBlue.standard};
        }
      }
    }
    .modal-window {
      width: 95%;
      left: 2.5%;
    }
  }
  .list-container {
    margin-top: 25px;
    scroll-behavior: smooth;

    .first-hr {
      display: none;
      margin-top: 0;
    }
    .store-container {
      padding-top: 25px;

      .dealer-name-link:link,
      .dealer-name-link:visited,
      .dealer-name-link:active {
        text-decoration: none;
      }
      .dealer-name-link:hover {
        text-decoration: underline;
      }
      .store-text {
        margin-left: -1px;
      }
      .store-info {
        padding: 5px 0 0 34px;
        svg {
          vertical-align: middle;
        }
        .distance {
          font-family:
            Acumin Pro,
            sans-serif;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          position: relative;
        }
        h6 {
          font-size: 12px;
          font-weight: bold;
          color: ${Colors.primary.white.tint};
          margin-bottom: 10px;
        }
        .store-phone {
          padding-top: 2px;
          padding-bottom: 2px;
          a {
            display: inline;
            color: ${Colors.primary.claytonBlue.standard};
            cursor: pointer;
          }
          p {
            display: none;
          }
        }
        span {
          padding-right: 7px;
          vertical-align: top;
        }
        .address-line-one {
          display: flex;

          .address-text {
            margin-left: 14px;
          }
        }
        .address-line-two {
          padding-left: 30px;
        }
        .view-homes a {
          color: ${Colors.primary.claytonBlue.standard};
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .no-locations {
      padding: 0 25px 25px;
    }
    .listings-searching {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
    }
  }
  .map {
    margin-top: 25px;
    width: 100%;
    height: 275px;
  }
  hr {
    height: 1px;
    border: 0;
    margin-top: 30px;
    border-top: 1px solid ${Colors.accent.grey5.standard};
  }
  .load-more {
    margin: 20px auto;
    max-width: ${mobile_width_percentage}%;
  }
  .states-container {
    background-color: ${Colors.accent.grey7.standard};
    padding-bottom: 100px;

    .states-inner-container {
      padding-top: 65px;
    }
  }
  .states-container-no-mobile-padding {
    margin-top: -70px;
  }
  @media only screen and (min-width: ${tablet_breakpoint}px) {
    .location-select-box {
      .modal-window {
        max-width: 300px;
      }
    }
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .map-container {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: flex-end;

      .location-listing,
      .map {
        margin-top: 0;
      }
      .map {
        width: 50%;
        order: 1;
        height: 787px;
        background-color: #ccc;
      }
      .location-select-box {
        order: 2;
        height: fit-content;
        width: 50%;
        padding: 25px 0 0 50px;
        margin: 0;
      }
      .list-container {
        order: 3;
        width: 50%;
        max-height: 670px;
        overflow: scroll;
        overflow-x: hidden;

        margin-top: -670px;
        .first-hr {
          display: block;
        }
        .no-locations {
          padding: 25px 0 0 50px;
        }
        .store-container {
          .store-text {
            margin-left: 0;
          }

          .store-info {
            h6 {
              float: right;
            }
            .h6 {
              text-align: right;
              margin-top: -20px;
              left: 0;
            }
            .view-homes {
              float: right;
              margin-top: -60px;
            }
            .distance {
              position: initial;
              left: 0;
              float: right;
            }
            .store-phone a {
              display: none;
            }
            .store-phone p {
              display: inline;
            }
          }
        }
        .listings-searching {
          width: 50%;
          padding-top: 30px;
        }
      }
      hr {
        margin-top: 26px;
      }
    }
    .load-more {
      display: none;
    }
    .states-container {
      margin-top: 0;

      .states-inner-container {
        background-color: ${Colors.accent.grey7.standard};
        max-width: ${desktop_container_maxwidth_compact}px;
        margin: 0 auto;
        padding: 40px;
      }
    }
  }
`;

export default LocationsStyles;
