import {
  DEFAULT_MAP_ZOOM,
  DEFAULT_MAP_CENTER,
  SEARCH_DEFAULT_DISTANCE,
} from './config';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
export const createMapOptions = (
  nearbyDealers,
  center = null,
  distance = SEARCH_DEFAULT_DISTANCE
) => {
  let zoom = DEFAULT_MAP_ZOOM - 1;
  let draggable = false;
  let zoomControl = false;
  let zoomDistance = distance;

  if (nearbyDealers && nearbyDealers.length > 0) {
    zoom = getZoomBasedOnDistance(zoomDistance);
    draggable = true;
    zoomControl = true;
  }

  let currentCenter = center || DEFAULT_MAP_CENTER;
  return {
    center: currentCenter,
    clickableIcons: false,
    draggable: draggable,
    draggableCursor: 'grab',
    draggingCursor: 'grabbing',
    gestureHandling: 'greedy',
    mapTypeControl: false,
    mapTypeControlOptions: {},
    streetViewControl: false,
    zoomControl: zoomControl,
    zoomControlOptions: {},
    minZoom: 5,
    maxZoom: 12,
    zoom: zoom,
    scrollwheel: false,
    optimized: false,
  };
};

export const getZoomBasedOnDistance = (distance) => {
  let zoom = 6;
  if (distance <= 13) {
    zoom = 11;
  } else if (distance <= 26) {
    zoom = 10;
  } else if (distance <= 52) {
    zoom = 9;
  } else if (distance <= 103) {
    zoom = 8;
  } else if (distance <= 206) {
    zoom = 7;
  } else if (distance <= 413) {
    zoom = 6;
  }

  if (
    typeof window !== 'undefined' &&
    window.innerWidth < variables.desktop_breakpoint
  ) {
    zoom -= 1;
  }

  return zoom;
};
