import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'pubweb-smokey/dist/colors';
import LoadingIndicatorStyles from './LoadingIndicator.styled';

export const LoadingIndicator = ({
  color = Colors.primary.claytonBlue.standard,
  width,
  height,
}) => (
  <LoadingIndicatorStyles color={color} width={width} height={height}>
    <div className="sk-fading-circle">
      <div className="sk-circle1 sk-circle" />
      <div className="sk-circle2 sk-circle" />
      <div className="sk-circle3 sk-circle" />
      <div className="sk-circle4 sk-circle" />
      <div className="sk-circle5 sk-circle" />
      <div className="sk-circle6 sk-circle" />
      <div className="sk-circle7 sk-circle" />
      <div className="sk-circle8 sk-circle" />
      <div className="sk-circle9 sk-circle" />
      <div className="sk-circle10 sk-circle" />
      <div className="sk-circle11 sk-circle" />
      <div className="sk-circle12 sk-circle" />
    </div>
  </LoadingIndicatorStyles>
);

LoadingIndicator.propTypes = {
  color: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any,
};

export default LoadingIndicator;
