export const allStates = [
  {
    name: 'alabama',
    abbr: 'al',
  },
  {
    name: 'alaska',
    abbr: 'ak',
  },
  {
    name: 'arizona',
    abbr: 'az',
  },
  {
    name: 'arkansas',
    abbr: 'ar',
  },
  {
    name: 'california',
    abbr: 'ca',
  },
  {
    name: 'colorado',
    abbr: 'co',
  },
  {
    name: 'connecticut',
    abbr: 'ct',
  },
  {
    name: 'delaware',
    abbr: 'de',
  },
  {
    name: 'florida',
    abbr: 'fl',
  },
  {
    name: 'georgia',
    abbr: 'ga',
  },
  {
    name: 'hawaii',
    abbr: 'hi',
  },
  {
    name: 'idaho',
    abbr: 'id',
  },
  {
    name: 'illinois',
    abbr: 'il',
  },
  {
    name: 'indiana',
    abbr: 'in',
  },
  {
    name: 'iowa',
    abbr: 'ia',
  },
  {
    name: 'kansas',
    abbr: 'ks',
  },
  {
    name: 'kentucky',
    abbr: 'ky',
  },
  {
    name: 'louisiana',
    abbr: 'la',
  },
  {
    name: 'maine',
    abbr: 'me',
  },
  {
    name: 'maryland',
    abbr: 'md',
  },
  {
    name: 'massachusetts',
    abbr: 'ma',
  },
  {
    name: 'michigan',
    abbr: 'mi',
  },
  {
    name: 'minnesota',
    abbr: 'mn',
  },
  {
    name: 'mississippi',
    abbr: 'ms',
  },
  {
    name: 'missouri',
    abbr: 'mo',
  },
  {
    name: 'montana',
    abbr: 'mt',
  },
  {
    name: 'nebraska',
    abbr: 'ne',
  },
  {
    name: 'nevada',
    abbr: 'nv',
  },
  {
    name: 'new-hampshire',
    abbr: 'nh',
  },
  {
    name: 'new-jersey',
    abbr: 'nj',
  },
  {
    name: 'new-mexico',
    abbr: 'nm',
  },
  {
    name: 'new-york',
    abbr: 'ny',
  },
  {
    name: 'north-carolina',
    abbr: 'nc',
  },
  {
    name: 'north-dakota',
    abbr: 'nd',
  },
  {
    name: 'ohio',
    abbr: 'oh',
  },
  {
    name: 'oklahoma',
    abbr: 'ok',
  },
  {
    name: 'oregon',
    abbr: 'or',
  },
  {
    name: 'pennsylvania',
    abbr: 'pa',
  },
  {
    name: 'rhode-island',
    abbr: 'ri',
  },
  {
    name: 'south-carolina',
    abbr: 'sc',
  },
  {
    name: 'south-dakota',
    abbr: 'sd',
  },
  {
    name: 'tennessee',
    abbr: 'tn',
  },
  {
    name: 'texas',
    abbr: 'tx',
  },
  {
    name: 'utah',
    abbr: 'ut',
  },
  {
    name: 'vermont',
    abbr: 'vt',
  },
  {
    name: 'virginia',
    abbr: 'va',
  },
  {
    name: 'washington',
    abbr: 'wa',
  },
  {
    name: 'west-virginia',
    abbr: 'wv',
  },
  {
    name: 'wisconsin',
    abbr: 'wi',
  },
  {
    name: 'wyoming',
    abbr: 'wy',
  },
];
