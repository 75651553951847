import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import PhoneSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/phone.svg';
import LocationLineSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/location-line.svg';
import {
  formatPhoneNumberFromNumber,
  formatStringToDisplayCase,
  formatDealerName,
  formatDistance,
} from 'pubweb-smokey/dist/utils/formatters';

const DealerInfo = ({
  pinNumber,
  dealerNumber,
  name,
  distance,
  phone,
  address1,
  city,
  state,
  postalCode,
  slug,
  selected = false,
  onContainerClick = null,
}) => {
  const selectedClass = selected ? 'selected' : '';
  return (
    <div
      id={`store-${dealerNumber}`}
      className={`store-container ${selectedClass}`}
      onClick={onContainerClick}
    >
      <div className="constrained-width">
        <div className="h5">
          <div className={`map-pin-navigator ${selectedClass}`}>
            <div className={`map-text ${selectedClass}`}>{pinNumber}</div>
          </div>
          <Link
            href={`/locations/${slug}/`}
            className="dealer-name-link dealer-name h5"
          >
            {formatDealerName(name)}
          </Link>
        </div>
        <div className="store-info">
          <p className="h5 distance standard">
            Distance: {formatDistance(distance)} miles
          </p>
          <div className="store-phone">
            <span>
              <span>
                <PhoneSvg />
              </span>
            </span>
            <a href={`tel:${formatPhoneNumberFromNumber(phone)}`}>
              {formatPhoneNumberFromNumber(phone)}
            </a>
            <p>{formatPhoneNumberFromNumber(phone)}</p>
          </div>
          <div className="store-address address-line-one">
            <div className="address-icon">
              <LocationLineSvg />
            </div>
            <div className="address-text standard">
              {formatStringToDisplayCase(address1)}
            </div>
          </div>
          {/* The div below was added because the two elements would lose their class and styling after F5 browser refresh in mobile view. */}
          <div>
            <p className="store-address address-line-two">
              {formatStringToDisplayCase(city)}, {state} {postalCode}
            </p>
            <p className="view-homes">
              <Link href={`/locations/${slug}/`}>view homes</Link>
            </p>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};

DealerInfo.propTypes = {
  address1: PropTypes.any,
  city: PropTypes.any,
  dealerNumber: PropTypes.any,
  distance: PropTypes.any,
  name: PropTypes.any,
  onContainerClick: PropTypes.any,
  phone: PropTypes.any,
  pinNumber: PropTypes.any,
  postalCode: PropTypes.any,
  selected: PropTypes.bool,
  slug: PropTypes.any,
  state: PropTypes.any,
};

export default DealerInfo;
