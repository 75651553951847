import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { PageContext } from '@contexts/PageContext';
import Colors from 'pubweb-smokey/dist/colors';

const ScrollUpButtonContainer = ({ isStudio = false }) => {
  const pageContext = useContext(PageContext);

  return (
    <ScrollToTop
      className="scroll-up-button"
      color={Colors.primary.white.standard}
      height={isStudio ? '20' : null}
      smooth="true"
      /*Path provided by react-scroll-up-button that is outdated, same path used to
    ensure no visual difference between the two packages*/
      svgPath="M27.414 12.586l-10-10c-0.781-0.781-2.047-0.781-2.828 0l-10 10c-0.781 0.781-0.781 2.047 0 2.828s2.047 0.781 2.828 0l6.586-6.586v19.172c0 1.105 0.895 2 2 2s2-0.895 2-2v-19.172l6.586 6.586c0.39 0.39 0.902 0.586 1.414 0.586s1.024-0.195 1.414-0.586c0.781-0.781 0.781-2.047 0-2.828z"
      style={{
        backgroundColor: Colors.primary.claytonBlue.standard,
        border: 'none',
        borderRadius: '50%',
        bottom: `${
          pageContext.state.scrollDirection === 'up' &&
          pageContext.state.mobileNavBarVisible
            ? pageContext.state.livePersonVisible
              ? '140px'
              : '100px'
            : '20px'
        }`,
        boxShadow: 'none',
        height: '50px',
        opacity: '1',
        paddingTop: isStudio ? '4px' : '',
        position: 'fixed',
        right: '20px',
        width: '50px',
        zIndex: '8',
      }}
      viewBox="0 0 32 32"
      width={isStudio ? '20' : null}
    />
  );
};

ScrollUpButtonContainer.propTypes = {
  isStudio: PropTypes.bool,
};

export default ScrollUpButtonContainer;
