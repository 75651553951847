import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import ScrollUpButtonContainer from '@components/Shared/ScrollUpButton/ScrollUpButtonContainer';
import MapComponent from '@components/Locations/MapComponent';
import Button from '@components/Shared/Buttons/Button';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import {
  buildQueryString,
  getQueryString,
  getQueryParams,
  scrollLock,
  scrollUnlock,
} from 'pubweb-smokey/dist/utils/utils';
import {
  DEFAULT_MAP_CENTER,
  SEARCH_DEFAULT_DISTANCE,
  SEARCH_ALLOWED_DISTANCES,
  getSiteUrl,
} from '@utils/config';
import { LocationsContext } from '@contexts/LocationsContext';
import { createMapOptions } from '@utils/mapUtils';
import {
  getCityStateDealerListingsNationwide,
  getNearbyDealers,
} from '@services/dealerService';
import { LocationsStyles } from './Locations.styled';
import LocationsStateCardListing from '@components/Locations/LocationsStateCardListing';
import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import DealerInfo from '@components/Locations/DealerInfo';
import LocationMarker from '@components/Locations/LocationMarker';
import NoLocation from '@components/Locations/NoLocation';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';

const LocationSelector = dynamic(
  () => import('@components/Shared/LocationSelector/LocationSelector'),
  { ssr: false }
);

const NUMBER_DEALERS_VISIBLE_MOBILE = 3;
const NUMBER_DEALERS_VISIBLE_MOBILE_INCREMENT = 3;

const Locations = ({
  allLocations,
  dealers,
  startingPostalCode,
  startingDistance,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [nearbyDealers, setNearbyDealers] = useState(dealers);
  const [isMobile, setIsMobile] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [resizeTimeout, setResizeTimeout] = useState(false);
  const router = useRouter();

  //The location context is controlling the postalCode and distance. This may need some adjustments as we use this in other areas!
  const locationsContext = useContext(LocationsContext);

  //On mobile, initially show the first few dealers.  On desktop show all dealers.
  //This is set when clicking a pin on the map, and will cause the navigation to scroll to that listing.
  const [mapNavigatorOptions, setMapNavigatorOptions] = useState(
    initMapNavigatorOptions()
  );

  //Defaulted to the lat and lng based on zip called for now. Could be changed to user's lat and lng, but this is
  //kind of nice too.
  const defaultCenter =
    nearbyDealers && nearbyDealers.length > 0
      ? {
          lat: nearbyDealers[0].latitude,
          lng: nearbyDealers[0].longitude,
        }
      : DEFAULT_MAP_CENTER;

  const [mapOptions, setMapOptions] = useState(
    createMapOptions(nearbyDealers, defaultCenter)
  );

  const updateDealerList = () => {
    setIsSearching(true);
    setSelectedLoc(null);
    setMapNavigatorOptions(initMapNavigatorOptions());

    getNearbyDealers(
      locationsContext.state.postalCode,
      locationsContext.state.distance,
      false
    ).then((result) => {
      setNearbyDealers(result);
      locationsContext.actions.setUpdating(false);
      setIsSearching(false);
    });
  };

  const updateQuery = () => {
    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      const newQuery = buildQueryString({
        postalCode: locationsContext.state.postalCode,
        distance: locationsContext.state.distance,
      });

      let newUrl = window.location.pathname + newQuery;
      if (
        newUrl.indexOf('/locations') !== -1 &&
        getQueryString() !== newQuery
      ) {
        window.history.replaceState(history.state, '', newUrl);
      }

      return newUrl;
    }
  };

  function initMapNavigatorOptions() {
    return {
      numDealersVisibleMobile: NUMBER_DEALERS_VISIBLE_MOBILE,
      storeName: '',
    };
  }

  useEffect(() => {
    if (
      !locationsContext.state.postalCode ||
      !locationsContext.state.distance
    ) {
      locationsContext.actions.updateLocation(
        startingPostalCode,
        startingDistance
      );
    }

    if (
      startingPostalCode !== null &&
      locationsContext.state.postalCode !== startingPostalCode
    ) {
      locationsContext.actions.updatePostalCode(startingPostalCode);
    }

    if (locationsContext.state.geocode !== startingDistance) {
      locationsContext.actions.updateDistance(startingDistance);
    }

    setInitialLoad(false);
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      updateQuery();
    }
    if (!nearbyDealers || locationsContext.state.updating) {
      updateDealerList();
    }
  }, [
    nearbyDealers,
    locationsContext.state.postalCode,
    locationsContext.state.distance,
  ]);

  useEffect(() => {
    const newCenter =
      nearbyDealers && nearbyDealers.length > 0
        ? {
            lat: nearbyDealers[0].latitude,
            lng: nearbyDealers[0].longitude,
          }
        : DEFAULT_MAP_CENTER;

    setMapOptions(
      createMapOptions(
        nearbyDealers,
        newCenter,
        locationsContext.state.distance
      )
    );
  }, [nearbyDealers]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const winWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      setIsMobile(winWidth < desktop_breakpoint);
    }

    const handleWindowResize = (e) => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }

      setResizeTimeout(
        setTimeout(() => {
          setIsMobile(e.target.outerWidth < desktop_breakpoint);
        }, 500)
      );
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  //Scrolling for the left-navigation.
  useLayoutEffect(() => {
    if (mapNavigatorOptions.storeName === '') {
      return;
    }

    //scroll to this element
    const dealerInfoElement = document.getElementById(
      mapNavigatorOptions.storeName
    );
    if (dealerInfoElement !== null) {
      if (isMobile) {
        window.scrollTo({
          top: dealerInfoElement.offsetTop - 10,
          behavior: 'smooth',
        });
      } else {
        const scrollDistance =
          dealerInfoElement.offsetTop -
          document.getElementById('store-list').offsetTop -
          2;
        document.getElementById('store-list').scrollTop = scrollDistance;
      }
    }
  }, [mapNavigatorOptions]);

  const mapMarkers =
    nearbyDealers !== null
      ? nearbyDealers.map((dealer, index) => {
          return (
            <LocationMarker
              key={`marker-${index}`}
              selected={dealer.dealerNumber === selectedLoc}
              location={{
                latitude: dealer.latitude,
                longitude: dealer.longitude,
              }}
              number={String(index + 1)}
              onClick={() => {
                setSelectedLoc(dealer.dealerNumber);
                pushGTMEvent('clickLocationsMapMarker');

                //A pin was clicked on the map.  The useLayoutEffect will perform the scrolling action.
                setMapNavigatorOptions({
                  ...mapNavigatorOptions,
                  numDealersVisibleMobile:
                    mapNavigatorOptions.numDealersVisibleMobile >= index + 1
                      ? mapNavigatorOptions.numDealersVisibleMobile
                      : index + 1,
                  storeName: 'store-' + dealer.dealerNumber,
                });
              }} //end click
            />
          );
        })
      : null;

  const storeInfo =
    nearbyDealers !== null
      ? nearbyDealers.map((dealer, index) => {
          return (
            <DealerInfo
              key={'dealer-info-' + (index + 1)}
              pinNumber={index + 1}
              dealerNumber={dealer.dealerNumber}
              selected={selectedLoc === dealer.dealerNumber}
              distance={dealer.distanceFromPostalCodeMidpoint}
              name={dealer.dealerName}
              address1={dealer.address1}
              address2={dealer.address2}
              city={dealer.city}
              state={dealer.stateProvince}
              postalCode={dealer.postalCode}
              phone={dealer.phoneNumber}
              slug={dealer.navigationSlug}
              onContainerClick={() => {
                setSelectedLoc(dealer.dealerNumber);
                setMapOptions({
                  ...mapOptions,
                  center: {
                    lat: dealer.latitude,
                    lng: dealer.longitude,
                  },
                });
              }}
            />
          );
        })
      : null;

  return (
    <LocationsStyles showLocationSelector={showLocationSelector}>
      <Head htmlAttributes={{ lang: 'en' }}>
        <title>Manufactured homes for sale near me | Clayton Homes</title>
        <meta
          name="description"
          content="Find modern manufactured and modular homes at Clayton locations near me to see mobile home prices and available floor plans."
        />
        <link rel="canonical" href={`${getSiteUrl(router.pathname)}`} />
        <meta
          property="og:image"
          content={`${getSiteUrl('/public-assets/images/clayton-logo.jpg')}`}
        />
      </Head>

      <ScrollUpButtonContainer />

      <div className="map-container">
        <div className="location-select-box constrained-width">
          <h3>Find A Retailer</h3>

          <LocationsContext.Consumer>
            {({ state, actions }) => {
              return (
                <>
                  <p className="purchase-location">
                    Purchase this home within
                    <a
                      href="#"
                      onClick={() => {
                        setShowLocationSelector(true);
                        scrollLock();
                      }}
                      suppressHydrationWarning={true}
                    >
                      &nbsp;{state.distance} miles of {state.postalCode}{' '}
                      <ChevronDwnSvg />
                    </a>
                  </p>
                  {showLocationSelector && (
                    <LocationSelector
                      applyButtonClassName="gtm-update-zip-dealers"
                      locationKey="dealers"
                      postalCode={state.postalCode}
                      distance={state.distance}
                      onUpdate={actions.updateLocation}
                      setUpdating={actions.setUpdating}
                      onCloseClick={() => {
                        setShowLocationSelector(false);
                        scrollUnlock();
                      }}
                    />
                  )}
                </>
              );
            }}
          </LocationsContext.Consumer>
        </div>
        <div className="map constrained-width">
          <MapComponent mapOptions={mapOptions}>{mapMarkers}</MapComponent>
        </div>
        <div id="store-list" className="list-container">
          <hr className="first-hr" />
          <LocationsContext.Consumer>
            {() => {
              return (
                <>
                  {nearbyDealers && nearbyDealers.length > 0 ? (
                    <>
                      {isMobile ? (
                        <>
                          {isSearching ? (
                            <div className="listings-searching">
                              <LoadingIndicator width="50" height="50" />
                            </div>
                          ) : (
                            ''
                          )}

                          {!isSearching &&
                            storeInfo.slice(
                              0,
                              mapNavigatorOptions.numDealersVisibleMobile
                            )}

                          {!isSearching &&
                            mapNavigatorOptions.numDealersVisibleMobile <
                              storeInfo.length && (
                              <Button
                                className="load-more"
                                automationId="locations-load-more"
                                buttonStyle="outlined"
                                fillContainer={true}
                                onClick={(e) => {
                                  e.target.blur();
                                  pushGTMEvent('loadMoreDealersClick');
                                  setMapNavigatorOptions({
                                    ...mapNavigatorOptions,
                                    numDealersVisibleMobile:
                                      mapNavigatorOptions.numDealersVisibleMobile +
                                      NUMBER_DEALERS_VISIBLE_MOBILE_INCREMENT,
                                    storeName: '',
                                  });
                                }}
                              >
                                Load More
                              </Button>
                            )}
                        </>
                      ) : isSearching ? (
                        <div className="listings-searching">
                          <LoadingIndicator width="50" height="50" />
                        </div>
                      ) : (
                        storeInfo
                      )}
                    </>
                  ) : (
                    <NoLocation loading={nearbyDealers === null} />
                  )}
                </>
              );
            }}
          </LocationsContext.Consumer>
        </div>
      </div>

      <div
        className={
          `states-container ` +
          (!isMobile && storeInfo && `states-container-no-mobile-padding`)
        }
      >
        <div className="states-inner-container">
          <LocationsStateCardListing allLocations={allLocations} />
        </div>
      </div>
    </LocationsStyles>
  );
};

Locations.propTypes = {
  allLocations: PropTypes.any,
  dealers: PropTypes.any,
  startingDistance: PropTypes.any,
  startingPostalCode: PropTypes.any,
};

export async function getServerSideProps({ req, query }) {
  let allLocations = null;
  let dealers = null;

  const queryParams = typeof req !== 'undefined' ? query : getQueryParams();

  //Only load dealers if we have info available!
  const loadDealers = typeof queryParams.postalCode !== 'undefined';

  //Get the postalCode and distance set by the params. If none is found, then we will wait until the page loads and do it there.
  let postalCode =
    queryParams && typeof queryParams.postalCode !== 'undefined'
      ? queryParams.postalCode
      : null;
  const distance =
    queryParams &&
    typeof queryParams.distance !== 'undefined' &&
    SEARCH_ALLOWED_DISTANCES.indexOf(parseInt(queryParams.distance)) !== -1
      ? parseInt(queryParams.distance)
      : SEARCH_DEFAULT_DISTANCE;

  //Requires a 5 digit zip code.
  const postalCodeRegex = new RegExp(/^\d{5}$/);
  if (!postalCodeRegex.test(postalCode)) {
    postalCode = '37804'; //Maryville, TN
  }

  // https://dev.cmhinfo.biz/WebServices/swagger/ui/index#!/CityStateDealerListings/CityStateDealerListings_Get
  await getCityStateDealerListingsNationwide().then((result) => {
    allLocations = result;
  });

  if (loadDealers) {
    await getNearbyDealers(postalCode, distance, false).then((result) => {
      dealers = result;
    });
  }

  return {
    props: {
      allLocations: allLocations,
      loadDealers: loadDealers,
      dealers: dealers,
      startingPostalCode: postalCode,
      startingDistance: distance,
    },
  };
}

export default Locations;
