import styled from 'styled-components';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const LocationsStateCardListingStyles = styled.div`
  .state-cards {
    h2 {
      text-align: center;
      margin-bottom: 25px;
    }
    .state-cards-listing {
      .state-card {
        display: block;
        text-decoration: none;
        width: 100%;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 18px 0 15px 18px;
        -webkit-tap-highlight-color: transparent;

        .accordion-chevron {
          float: right;
          margin-right: 17px;
          margin-top: -32px;
        }
      }
    }
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .state-cards {
      h2 {
        text-align: center;
      }
      .state-cards-listing {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .state-card {
          display: block;
          margin-bottom: 40px;
          width: 46%;
          padding: 20px;
          -webkit-tap-highlight-color: transparent;

          .accordion-chevron {
            float: right;
            margin-right: 17px;
            margin-top: -36px;
          }
        }
      }
    }
  }
`;
