import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import ChevronRight from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import { formatStringToDisplayCase } from 'pubweb-smokey/dist/utils/formatters';
import { LocationsStateCardListingStyles } from './LocationsStateCardListing.styled';
import { allStates } from './_states';

const LocationsStateCardListing = ({ allLocations }) => {
  const resetStatePageScrollPosition = () => {
    //localStorage.setItem("LocationStatesPageScrollPosition", 0);
  };

  const getNumStoresPerState = (stateName) => {
    let totalDealersPerState = 0;

    const selectedState = allLocations.filter(
      (state) => state.name === stateName
    );

    if (selectedState?.[0]?.cities.length) {
      //loop through all dealers in a city for an accurate count of stores (and not just cities where dealersstores are present)
      //lots of cities have multiple dealers/stores
      for (let i = 0; i < selectedState[0].cities.length; i++) {
        totalDealersPerState += selectedState[0].cities[i].dealers.length;
      }

      return totalDealersPerState;
    } else {
      return 0;
    }
  };

  const getTotalStoresNationwide = (allLocations) => {
    let totalLocations = 0;

    for (let i = 0; i < allLocations.length; i++) {
      if (allLocations[i].cities.length) {
        //once you have each city, get each dealer from that
        for (let j = 0; j < allLocations[i].cities.length; j++) {
          totalLocations += allLocations[i].cities[j].dealers.length;
        }
      }
    }

    return totalLocations;
  };

  return (
    <LocationsStateCardListingStyles>
      <div className="state-cards constrained-width">
        <h2>All {getTotalStoresNationwide(allLocations)} locations</h2>

        <div className="state-cards-listing">
          {allStates.map((state, i) => {
            return getNumStoresPerState(
              formatStringToDisplayCase(state.name.replace('-', ' '))
            ) >= 1 ? (
              //linked card for states with stores
              <Link
                key={i}
                href={`/locations/${state.name}`}
                onClick={() => resetStatePageScrollPosition()}
                className="state-card"
              >
                <h3 className="h4">
                  {formatStringToDisplayCase(state.name.replace('-', ' '))}
                </h3>
                <p>
                  {getNumStoresPerState(
                    formatStringToDisplayCase(state.name.replace('-', ' '))
                  )}
                  {getNumStoresPerState(
                    formatStringToDisplayCase(state.name.replace('-', ' '))
                  ) > 1
                    ? ' stores'
                    : ' store'}
                </p>
                <ChevronRight className="accordion-chevron" />
              </Link>
            ) : (
              //empty/unlinked card for states with no stores (still show those states)
              <span key={`state-card-${i}`} className="state-card">
                <h3 className="h4">
                  {formatStringToDisplayCase(state.name.replace('-', ' '))}
                </h3>
                <p>0 stores</p>
              </span>
            );
          })}
        </div>
      </div>
    </LocationsStateCardListingStyles>
  );
};

LocationsStateCardListing.propTypes = {
  allLocations: PropTypes.array,
};

export default LocationsStateCardListing;
