import React from 'react';
import PropTypes from 'prop-types';

const NoLocation = ({ loading }) => {
  return (
    <div className="no-locations">
      <div>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            <h4>No home centers found in your area.</h4>
            <p className="caption">
              {' '}
              Try expanding your search radius or changing your ZIP code.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

NoLocation.propTypes = {
  loading: PropTypes.any,
};

export default NoLocation;
